import React from 'react';
import { Button, ButtonGroup, Card, CardBody, Col, FormControl, FormSelect, Row, Spinner, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useMembers } from './useMembers';


const Members: React.FC = () => {

    const { models, operations } = useMembers();
    const { loading, members, expired, keywords } = models;
    const { setExpired, setKeywords, nextPage, previousPage } = operations;

    const navigate = useNavigate();

    return (
        <Card bg="light" border="light" className="w-100">
            <CardBody>

                <Row>
                    <Col style={{ textAlign: 'left' }} >
                        <h3>Members</h3>
                    </Col>
                    <Col xs={"auto"}>
                        <FormControl
                            placeholder={"search..."}
                            value={keywords}
                            onChange={(e) => setKeywords(e.target.value)}
                            className="mb-2"
                        />
                    </Col>
                    <Col xs={"auto"}>
                        <FormSelect value={expired} onChange={(e) => setExpired(e.target.value)}>
                            <option>All Members</option>
                            <option value="true">Active Members</option>
                            <option value="false">Expired Members</option>
                        </FormSelect>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {loading ? <Spinner />
                            : <Table>
                                <thead><tr><th>First Name</th> <th>Last Name</th><th>Email</th><th>Membership Status</th><th /></tr></thead>
                                <tbody>
                                    {members && members.map((member) => {
                                        return (
                                            <tr key={member.memberId}>
                                                <td>{member.firstname}</td>
                                                <td>{member.lastname}</td>
                                                <td>{member.email}</td>
                                                <td>{(member.memberships && member.memberships.length > 0) ? "Active" : "Expired"}</td>
                                                <td>
                                                    <Button
                                                        size="sm"
                                                        variant="outline-primary"
                                                        onClick={() => navigate("/member/" + member.memberId)}
                                                    >
                                                        <i className="bi bi-chevron-right"></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        }
                    </Col>
                </Row>


                <ButtonGroup className="m-2" >
                    <Button disabled={!previousPage} onClick={previousPage}>
                        Back
                    </Button>
                    <Button disabled={!nextPage} onClick={nextPage}>
                        Next
                    </Button>
                </ButtonGroup>

            </CardBody>
        </Card>
    );
};

export default Members;