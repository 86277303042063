import React, { useEffect } from 'react';
import { Alert, Button, Card, CardBody, Col, Container, FormControl, FormSelect, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import SegmentPreviewModal from '../Modals/SegmentPreviewModal';
import { useSegment } from './useSegment';

const SegmentDetail: React.FC = () => {

    const { segmentId } = useParams();
    const { models, operations } = useSegment({ segmentId });
    const { segment, properties } = models;
    const {
        handleDeleteSegment,
        handleDeleteSegmentOr,
        handleUpdateSegmentOr,
        handleCreateSegmentAnd,
        handleCreateSegmentOr

    } = operations;

    const navigate = useNavigate();

    const segmentPropertyTypes = (property?: string) => {

        let type = properties?.filter(x => x.name === property)[0]?.type;

        if (type === 'text')
            return ['Equals', 'Contains'];

        if (type === 'date')
            return ['On Or Before', 'On Or After'];

        if (type === 'selection')
            return ['Is', 'Is Not'];

        return [];
    }

    const segmentPropertyInputType = (property?: string) => properties?.filter(x => x.name === property)[0]?.type;

    const segmentPropertyOptions = (property?: string) => properties?.filter(x => x.name === property)[0]?.options ?? [];



    useEffect(() => {
        console.log("got segment", segment);
    }, [segment])

    return (
        <Card bg="light" border="light" className="w-100">
            <CardBody style={{ textAlign: 'left' }}>

                <Row className=" ">
                    <Col xs="auto">
                        <Button variant="outline-primary"
                            onClick={() => navigate("/segments")}
                        ><i className="bi bi-chevron-left"></i></Button>
                    </Col>
                    {segment && <>
                        <Col >

                            <h3 className=""> {segment.name}</h3>
                        </Col>
                        <Col xs="auto">
                            <Button
                                size="sm"
                                variant="outline-danger"
                                disabled={false}
                                onClick={handleDeleteSegment}
                            ><i className="bi bi-trash me-1"></i>Delete</Button>
                        </Col>
                    </>
                    }

                </Row>

                {segment?.configuration && properties &&
                    <Container>
                        {segment.configuration?.segmentAnd?.map((and, i) => {
                            return (
                                <Row key={i} className="mt-1 g-4">
                                    {i !== 0 ? <Col xs={12}>And</Col> : <Col xs={12}>This segment will return a list of members based on the following conditions.</Col>}
                                    <Col lg={12}>
                                        <Alert variant="secondary">
                                            {and.segmentOr.map((or, ii) => {
                                                return (<p key={or.id}>
                                                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Col xs="auto">{ii == 0 ? "Where " : "or "} the member's</Col>
                                                        <Col xs="auto">
                                                            <FormSelect
                                                                value={or.propertyName?.toString()}
                                                                style={{ maxWidth: '20em' }}
                                                                onChange={(e) => handleUpdateSegmentOr(or.id?.toString() ?? "", 'propertyName', e.target.value)}
                                                            >
                                                                <option value=""> - </option>
                                                                {properties.map(option => {
                                                                    return (<option key={option.name} value={option.name}>{option.name.replace(/([A-Z])/g, ' $1').trim()}</option>)
                                                                })}
                                                            </FormSelect>
                                                        </Col>

                                                        <Col xs="auto">
                                                            <FormSelect style={{ maxWidth: '20em' }}
                                                                value={or.propertyType?.toString()}
                                                                onChange={(e) => handleUpdateSegmentOr(or.id?.toString() ?? "", 'propertyType', e.target.value)}
                                                            >
                                                                <option value=""> - </option>
                                                                {segmentPropertyTypes(or.propertyName?.toString()).map(option => {
                                                                    return (<option key={option} value={option}>{option}</option>)
                                                                })}
                                                            </FormSelect>
                                                        </Col>
                                                        <Col>
                                                            {segmentPropertyInputType(or.propertyName?.toString()) === 'selection' ?
                                                                <FormSelect
                                                                    value={or.propertyValue?.toString()}
                                                                    onChange={(e) => handleUpdateSegmentOr(or.id?.toString() ?? "", 'propertyValue', e.target.value)}
                                                                >
                                                                    <option value=""> - </option>
                                                                    {
                                                                        segmentPropertyOptions(or.propertyName?.toString()).map(option => {
                                                                            return (<option key={option} value={option}>{option}</option>)
                                                                        })
                                                                    }
                                                                </FormSelect>
                                                                :
                                                                <FormControl
                                                                    type={segmentPropertyInputType(or.propertyName?.toString())}
                                                                    value={or.propertyValue?.toString()}
                                                                    onChange={(e) => handleUpdateSegmentOr(or.id?.toString() ?? "", 'propertyValue', e.target.value)}
                                                                />
                                                            }
                                                        </Col>
                                                        <Col>
                                                            <Button
                                                                variant="link"
                                                                disabled={i === 0 && ii === 0}
                                                                onClick={() => handleDeleteSegmentOr(or.id?.toString())}
                                                            >
                                                                <i className="bi bi-x-lg"></i>
                                                            </Button>
                                                        </Col>
                                                    </Row>




                                                </p>)
                                            })}

                                            <Button
                                                variant="outline-primary"
                                                size="sm"
                                                onClick={() => handleCreateSegmentOr(and.segmentOr[0].id?.toString())}
                                            >
                                                Add Condition
                                            </Button>
                                        </Alert>
                                    </Col>
                                </Row>
                            )
                        })}

                        <Row className="mt-2">
                            <Col>
                                <Button
                                    className="me-1"
                                    onClick={() => handleCreateSegmentAnd()}
                                >Add Condition</Button>

                                <SegmentPreviewModal
                                    segmentId={segment.segmentId}
                                    name={segment.name}
                                />

                            </Col>
                        </Row>


                    </Container>
                }


            </CardBody>
        </Card>
    );
};

export default SegmentDetail;