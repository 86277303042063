import { gql } from '@apollo/client';
import { segmentGQL } from '../gql/segmentGQL';

export const CREATE_SEGMENT = gql`
  mutation segmentCreateMutation($segment:CreateSegmentCommandInput) {
    segmentCreate(segment: $segment) {
       ${segmentGQL}
    }
  }
`;

export const UPDATE_SEGMENT = gql`
  mutation segmentUpdateMutation($segment:UpdateSegmentCommandInput) {
    segmentUpdate(segment: $segment) {
       ${segmentGQL}
    }
  }
`;

export const DELETE_SEGMENT = gql`
  mutation segmentDeleteMutation($segment:DeleteSegmentCommandInput) {
    segmentDelete(segment: $segment) {
       ${segmentGQL}
    }
  }
`;
