import React, { useEffect } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import ChildLevelSelect from './ChildLevelSelect';
import EMDRLevelSelect from './EMDRLevelSelect/EMDRLevelSelect';
import { UpdateMemberDetailsModalProps } from './UpdateMemberDetailsModalProps';
import { useUpdateMemberDetails } from './useUpdateMemberDetails';


const UpdateMemberDetailsModal: React.FC<UpdateMemberDetailsModalProps> = ({ ...props }) => {

    const { member } = { ...props } as UpdateMemberDetailsModalProps;
    const { models, operations } = useUpdateMemberDetails({ ...props });
    const { caLevel, caDate, emdrLevel, emdrDate, show, loading } = models;
    const { setEmdrLevel, setEmdrDate, setCaLevel, setCaDate, handleShow, handleClose, handleSave } = operations;



    return (
        member ?
            <>
                <Button
                    size="sm"
                    variant="outline-primary"
                    className="mb-2 me-2"
                    onClick={handleShow}
                >
                    <i className="bi bi-pencil me-1"></i>
                    Change
                </Button>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{member.firstname + '\'s Details'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-4">
                            <Col>
                                <Form.Group>
                                    <Form.Label>EMDR Training Level</Form.Label>
                                    <EMDRLevelSelect
                                        name="emdrLevel"
                                        onChange={(e) => setEmdrLevel(e.target.value)}
                                        value={emdrLevel}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Date Achieved</Form.Label>
                                    <Form.Control
                                        name="emdrLevelDate"
                                        type="date"
                                        required
                                        value={emdrDate?.toString() ?? ""}
                                        onChange={(e) => setEmdrDate(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Child Training Level</Form.Label>
                                    <ChildLevelSelect
                                        name="childLevel"
                                        onChange={(e) => setCaLevel(e.target.value)}
                                        value={caLevel}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Date Achieved</Form.Label>
                                    <Form.Control
                                        name="childLevelDate"
                                        type="date"
                                        value={caDate ?? ""}
                                        onChange={(e) => setCaDate(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleSave}
                            disabled={loading}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            :
            <Button variant="outline-primary" disabled >
                Change
            </Button >);
};

export default UpdateMemberDetailsModal;