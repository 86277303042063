import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { UpdateAccreditationModalProps } from './UpdateAccreditationModalProps';



export const useUpdateAccreditation = ({ ...props }) => {

    var { accreditation, onUpdate } = { ...props } as UpdateAccreditationModalProps;

    const [show, setShow] = useState(false);

    const [endDate, setEndDate] = useState<string>((accreditation?.endDate ? new Date(accreditation.endDate).toISOString() : new Date().toISOString()).slice(0, 10));

    const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSave = () => {
        setLoading(true);
        let updated = cloneDeep(accreditation);
        if (updated) {
            updated.endDate = new Date(endDate);
            onUpdate(updated).then(() => {
                handleClose();
                setLoading(false);
            });
        }
    };

    return {
        models: {
            endDate, show, loading
        },
        operations: {
            handleShow, handleSave, handleClose, setEndDate
        }
    };
};
