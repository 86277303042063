import { gql } from '@apollo/client';
import { eventGQL, eventParticipantGQL } from '../gql/eventGQL';

export const CREATE_EVENT = gql`
  mutation eventCreateMutation($evt:CreateEventCommandInput) {
    eventCreate(evt: $evt) {
       ${eventGQL}
    }
  }
`;


export const CREATE_EVENT_PARTICIPANT = gql`
  mutation eventParticipantCreateMutation($eventParticipant:CreateEventParticipantCommandInput) {
    eventParticipantCreate(eventParticipant: $eventParticipant) {
       ${eventParticipantGQL}
    }
  }
`;


export const UPDATE_EVENT_EXPIRY_DATE = gql`
  mutation eventUpdateExpiryDateMutation($evt:UpdateEventExpiryDateCommandInput) {
    eventUpdateExpiryDate(evt: $evt) {
       ${eventGQL}
    }
  }
`;

export const UPDATE_EVENT_LINKS = gql`
  mutation eventUpdateLinksMutation($evt:UpdateEventLinksCommandInput) {
    eventUpdateLinks(evt: $evt) {
       ${eventGQL}
    }
  }
`;

export const UPDATE_EVENT_CERTIFICATE_TEMPLATE = gql`
  mutation eventUpdateCertificateTemplateMutation($eventCertificateTemplate:UpdateEventCertificateTemplateCommandInput) {
    eventUpdateCertificateTemplate(eventCertificateTemplate: $eventCertificateTemplate) {
       ${eventGQL}
    }
  }
`;

