import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { CreateAccreditationModalProps } from './CreateAccreditationModalProps';



export const useCreateAccreditation = ({ ...props }) => {

    var { onCreate } = { ...props } as CreateAccreditationModalProps;

    const [show, setShow] = useState(false);

    const [name, setName] = useState<string>("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSave = () => {
        onCreate(name).then(() => {

            console.log("oncreate finished!");

            handleClose();
        });
    };

    return {
        models: {
            name, show
        },
        operations: {
            handleShow, handleSave, handleClose, setName
        }
    };
};
