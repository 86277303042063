import { gql } from '@apollo/client';
import { noteGQL } from './queries';

export const CREATE_NOTE = gql`
  mutation noteCreateMutation($note:CreateNoteCommandInput) {
    noteCreate(note: $note) {
       ${noteGQL}
    }
  }
`;



