import { useQuery } from '@apollo/client';
import React from 'react';
import { Form } from 'react-bootstrap';
import { GET_ACCREDITATION_TYPES } from '../../../graphql/accreditation/queries';
import { AccreditationTypeSelectProps } from './AccreditationTypeSelectProps';
import { useAccreditationTypes } from './useAccreditationTypes';

const AccreditationTypeSelect: React.FC<AccreditationTypeSelectProps> = ({ name, value, onChange }) => {

    const { data } = useQuery(GET_ACCREDITATION_TYPES);
    const { models } = useAccreditationTypes({ data });
    const { accreditationTypes } = models;

    return (
        <Form.Control
            as="select"
            name={name}
            value={value}
            onChange={(e) => onChange(e)}
            required
        >
            {accreditationTypes ? <>
                <option value="">Select accreditation type</option>
                {accreditationTypes.map((option, index) => (
                    <option key={index} value={option.accreditationTypeId}>
                        {option.name}
                    </option>
                ))}
            </>
                :
                <option value=""></option>
            }
        </Form.Control>

    );
};

export default AccreditationTypeSelect;