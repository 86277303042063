import { gql } from '@apollo/client';
import { memberItemGQL } from '../gql/memberGQL';
import { segmentGQL } from '../gql/segmentGQL';
import { segmentConditionGQL } from '../gql/segmentConditionGQL';


export const GET_SEGMENT_MEMBERS = gql`
  query GetSegmentMembers($id:String!){
    segmentMembers(id:$id){
        ${memberItemGQL}
    }
  }
`;

export const GET_SEGMENT = gql`
  query GetSegment($id:String!){
    segment(id:$id){
        ${segmentGQL}
        configuration {
            segmentAnd {
                segmentOr {
                    ${segmentConditionGQL}
                }
            }
        }
    }
  }
`;

export const GET_SEGMENTS = gql`
  query GetSegments($first:Int $after:String $where:SegmentUpdateModelFilterInput) {
    segments(first:$first after:$after where:$where){
        totalCount
        pageInfo{
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        }
        nodes{
           ${segmentGQL}
        }
    }
}
`;

export const GET_SEGMENT_PROPERTIES = gql`
  query GetSegmentProperties {
    segmentProperties{
        name
        type
        options
    }
}
`;