import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { CREATE_NOTE } from '../../../graphql/note/mutations';
import { CreateNoteCommandInput } from '../../../graphql/__generated__/graphql';
import useDocuments from '../../../hooks/useDocuments';
import { CreateNoteModalProps } from './CreateNoteModalProps';



export const useCreateNote = ({ ...props }) => {


    const { error } = useDocuments();

    const [note, setNote] = useState<string>("");

    const [isValid, setIsValid] = useState<boolean>(false);

    const [createNote] = useMutation(CREATE_NOTE);



    const { memberId, noteId, onCreate } = { ...props } as CreateNoteModalProps

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setIsValid(false);
        setNote("")
        onCreate();
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const handleSave = () => {
        handleUpload().then(() => {
            handleClose();
        })
    };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {

        let newNote = event.target.value;

        setNote(newNote);
        setIsValid(newNote.length > 0);
    }

    const handleUpload = () => {
        return new Promise(async (resolve) => {
            if (note.length > 0) {
                await createNote({
                    variables: {
                        note: {
                            memberId,
                            noteId,
                            value: note
                        } as CreateNoteCommandInput
                    }
                });

                setNote("");
                resolve(true);
            }
        });
    };




    return {
        models: {
            note, show, error, isValid
        },
        operations: {
            handleShow, handleSave, handleClose, setNote, handleChange
        }
    };
};
