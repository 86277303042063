import { gql } from '@apollo/client';

export const GET_PROFILE = gql`
  query GetProfile {
    profile {  
        id
        membershipNumber
        name
        title
        firstName
        lastName
        email
        phone
        organisation
        website
        avatar
        address {
          id
          addressLine1
          addressLine2
          city
          country
          postcode
        }
    }
  }
`;