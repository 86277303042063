import { useState } from 'react';
import Resumable from 'resumablejs';
import { VideoUploadRequest } from '../interfaces/VideoUploadRequest';

const VIDEO_API = process.env.REACT_APP_VIDEOAPI_ENDPOINT;

const useVideos = () => {
    const [error, setError] = useState<string | null>(null);

    const [progress, setProgress] = useState(0);
    const [totalSizeMb, setTotalSizeMb] = useState(0);
    const [uploadedSizeMb, setUploadedSizeMb] = useState(0);


    const uploadFile = (request: VideoUploadRequest) => {
        return new Promise((resolve, reject) => {
            try {

                const resumable = new Resumable({
                    target: `${VIDEO_API}/UploadChunk`,
                    query: {
                        eventId: request.eventId,
                        name: request.name,
                        description: request.description
                    },
                    chunkSize: 10 * 1024 * 1024, //10MB
                });

                resumable.on('fileSuccess', () => {
                    setProgress(0);
                });

                resumable.on('fileError', (file, message) => {
                    setError(message);
                    setProgress(0);
                });

                resumable.on('progress', () => {

                    let total = resumable.files[0].size / 1024 / 1024;

                    setTotalSizeMb(total);
                    setUploadedSizeMb(total * resumable.progress());

                    setProgress(resumable.progress());
                });

                resumable.on('fileAdded', () => {
                    resumable.upload();
                });

                resumable.on('complete', () => {
                    resolve(true);
                })

                resumable.addFile(request.file);

            } catch (error) {
                setError('Error uploading file.');
                return null;
            }
        })
    };

    const downloadFile = async (videoId: string, fileName?: string): Promise<void> => {
        try {
            const response = await fetch(`${VIDEO_API}/${videoId}`);

            if (!response.ok) {
                throw new Error('File download failed.');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = fileName ?? "download-file";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            setError('Error downloading file.');
        }
    };



    return {
        uploadFile,
        downloadFile,
        error,
        progress,
        totalSizeMb,
        uploadedSizeMb
    };
};

export default useVideos;
