import { gql } from '@apollo/client';
import { broadcastGQL, broadcastItemGQL } from '../gql/broadcastGQL';


export const GET_BROADCASTS = gql`
  query GetBroadcasts($first:Int $after:String $where:BroadcastFilterInput) {
    broadcasts(first:$first after:$after where:$where){
        totalCount
        pageInfo{
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        }
        nodes{
            ${broadcastItemGQL}
        }
    }
}
`;

export const GET_BROADCAST = gql`
  query GetBroadcast($id:string){
    broadcast(id:$id){
        ${broadcastGQL}
    }
  }
`;

export const GET_BROADCAST_STATISTICS = gql`
  query GetBroadcastStatistics{
    broadcastStatistics{
        date
        stats {
            metrics {
                requests
                processed
                delivered
                bounces
                opens
                bounces
                clicks            
            }
        }
    }
  }
`;

