import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import MemberNotes from '../MemberNotes';
import { MemberNoteProps } from '../MemberNotes/MemberNoteProps';
import { useMemberNotes } from '../MemberNotes/useMemberNotes';
import MemberSectionContainer from '../MemberSectionContainer';
import CreateNoteModal from '../Modals/CreateNoteModal';


const MemberNotesSection: React.FC<MemberNoteProps> = (props) => {


    const { models, operations } = useMemberNotes({ ...props });
    const { member } = models;
    const { handleReloadMemberNotes } = operations;

    return (
        <MemberSectionContainer

            title="Notes"
            action={
                <CreateNoteModal
                    memberId={member.memberId}
                    onCreate={() => {
                        return new Promise((resolve, reject) => {
                            resolve(handleReloadMemberNotes());
                        })
                    }}
                />
            }
        >


            <MemberNotes {...props} />



        </MemberSectionContainer>);
};

export default MemberNotesSection;