import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { MemberSectionContainerProps } from './MemberSectionContainerProps';

const MemberSectionContainer: React.FC<MemberSectionContainerProps> = ({ children, title, action }) => {
    return (
        <div style={{ borderLeft: '6px solid #eee' }} className="ps-2 mb-3">
            <Row>
                <Col>
                    {title && <h5>{title} </h5>}
                </Col>
                {action &&
                    <Col xs={"auto"}>
                        {action}
                    </Col>
                }
            </Row>
            {children}
        </div>
    );
};

export default MemberSectionContainer;