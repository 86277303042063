import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { UpdateAccreditationModalProps } from './UpdateAccreditationModalProps';
import { useUpdateAccreditation } from './useUpdateAccreditation';


const UpdateAccreditationModal: React.FC<UpdateAccreditationModalProps> = ({ ...props }) => {

    const { accreditation } = { ...props } as UpdateAccreditationModalProps;
    const { models, operations } = useUpdateAccreditation({ ...props });
    const { endDate, show, loading } = models;
    const { handleShow, handleClose, handleSave, setEndDate } = operations;

    return (
        accreditation ?
            <>
                <Button variant="outline-primary" onClick={handleShow} size="sm">
                    <i className="bi bi-pencil me-1"></i>
                    Change
                </Button>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{accreditation?.accreditationType?.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Expiry Date</Form.Label>
                            <Form.Control
                                type="date"
                                required
                                value={endDate?.toString() ?? ""}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleSave}
                            disabled={loading}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            :
            <Button variant="outline-primary" disabled >
                Change
            </Button >);
};

export default UpdateAccreditationModal;