import React from 'react';
import { Badge, Col, FormControl, ListGroup, Row, Spinner } from 'react-bootstrap';
import { MemberEventProps } from './MemberEventProps';
import { useMemberEvents } from './useMemberEvents';

const MemberEvents: React.FC<MemberEventProps> = (props) => {


    const { models, operations } = useMemberEvents({ ...props });
    const { events, keywords, member, loading } = models;
    const { setKeywords } = operations;

    return (
        <>
            <Row>
                <Col xs={"auto"} >
                    <FormControl
                        placeholder={"search..."}
                        value={keywords}
                        onChange={(e) => setKeywords(e.target.value)}
                        className="mb-2"
                    />
                </Col>

            </Row>
            <Row>
                <Col>
                    {loading ? <Spinner /> :
                        <ListGroup className="mt-2" style={{ overflowY: 'auto' }}>
                            {events && events.map(evt => {
                                return (
                                    <ListGroup.Item key={evt.eventId} variant={new Date(evt.createdDate) > new Date() ? undefined : "secondary"}>
                                        <Row>
                                            <Col>
                                                {evt.name}{evt.cancelled === true && <Badge className="m-1" bg="danger">Cancelled</Badge>}

                                            </Col>
                                            <Col xs={"auto"} style={{ textAlign: 'right' }}>

                                                <Badge
                                                    style={evt.cancelled ? { opacity: 0.4 } : undefined}>
                                                    {new Date(evt.startDate).toLocaleDateString("en-gb")}
                                                </Badge>



                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    }
                </Col>
            </Row>

        </>

    );
};

export default MemberEvents;