import { useEffect, useState } from 'react';
import { EmdrTrainingLevelItem } from '../../../../graphql/__generated__/graphql';

export const useEmdrLevels = ({ data }: any) => {

    const [emdrTrainingLevels, setEmdrTrainingLevels] = useState<EmdrTrainingLevelItem[]>();

    useEffect(() => {
        if (data?.emdrTrainingLevels && !emdrTrainingLevels)
            handleLoadEmdrLevels();
    });


    const handleLoadEmdrLevels = () => {
        try {
            setEmdrTrainingLevels(data.emdrTrainingLevels
                .filter((x: EmdrTrainingLevelItem) => { return x.name?.startsWith("Part") || x.name === "MSc" })
                .reverse()
                .map((x: EmdrTrainingLevelItem) => {
                    return {
                        emdrTrainingLevelId: x.emdrTrainingLevelId,
                        name: x.name
                    };
                }) as EmdrTrainingLevelItem[]);
        } catch (error) {
            console.error('Error processing emdr levels:', error);

        }
    };

    return {
        models: { emdrTrainingLevels }
    };

};
