import { useState } from 'react';

export const useEventLinks = ({ value, onUpdate }: { value: string[], onUpdate: (value?: string[]) => Promise<any> }) => {

    const [show, setShow] = useState(false);

    const [conferenceUrl, setConferenceUrl] = useState<string>(value[0]);
    const [surveyUrl, setSurveyUrl] = useState<string>(value[1]);

    const [loading, setLoading] = useState(false);

    const handleSave = () => {
        setLoading(true);
        onUpdate([conferenceUrl, surveyUrl]).then(() => {
            handleClose();
            setLoading(false);
        });
    }

    const handleOpen = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    return {
        models: { show, conferenceUrl, surveyUrl, loading },
        operations: { setConferenceUrl, setSurveyUrl, handleOpen, handleClose, handleSave }
    };

};
