import React from 'react';
import { Badge, Button, ButtonGroup, Card, CardBody, Col, FormControl, Row, Spinner, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useEvents } from '../../hooks/useEvents';
import EventDocumentsModal from '../Modals/EventDocumentsModal';
import UpdateCertificateModal from '../Modals/UpdateCertificateModal';
import UploadVideoModal from '../Modals/UploadVideoModal';
import EditableExpiryDate from './EditableExpiryDate/EditableExpiryDate';
import EventLinksModal from '../Modals/EventLinksModal';
import { ParticipantModal } from './ParticipantModal/ParticipantModal';

const Events: React.FC = () => {

    const { models, operations } = useEvents();
    const { loading, events, keywords } = models;
    const { reload, setKeywords, nextPage, previousPage, handleUpdateExpiryDate, handleUpdateLinks } = operations;

    const navigate = useNavigate();

    return (
        <Card bg="light" border="light" className="w-100">
            <CardBody>
                <Row>
                    <Col style={{ textAlign: 'left' }} >
                        <h3>Events</h3>
                    </Col>
                    <Col xs={"auto"}>
                        <FormControl
                            placeholder={"search..."}
                            value={keywords}
                            onChange={(e) => setKeywords(e.target.value)}
                            className="mb-2"
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {loading ? <Spinner />
                            : <Table>
                                <thead><tr style={{ textAlign: 'left' }}>
                                    <th>Name</th>
                                    <th>Participants</th>
                                    <th>Start</th>
                                    <th >End</th>
                                    <th title="The date when this event will become unavailable in the member portal">Expires <i className="bi bi-info-circle"></i></th>
                                    <th />
                                    <th />
                                </tr></thead>
                                <tbody>
                                    {events && events.map((event) => {
                                        return (
                                            <tr style={{ textAlign: 'left' }} key={event.eventId + event.name}>
                                                <td>{event.name}  {event.cancelled && <Badge pill className="ms-2" bg="danger">Cancelled</Badge>}</td>
                                                <td>
                                                    {event.participants &&


                                                        <ParticipantModal event={event} />


                                                    }
                                                </td>
                                                <td width="140">
                                                    <i className="bi bi-calendar me-1"></i>{(new Date(event.startDate).toLocaleDateString("en-gb"))}
                                                    <br />
                                                    <i className="bi bi-clock ms-3 me-1"></i>{(new Date(event.startDate).toLocaleTimeString("en-gb"))}

                                                </td>

                                                {event.endDate ?
                                                    <td width="140">
                                                        <i className="bi bi-calendar me-1"></i>{(new Date(event.endDate).toLocaleDateString("en-gb"))}
                                                        <br />
                                                        <i className="bi bi-clock ms-3 me-1"></i>{(new Date(event.endDate).toLocaleTimeString("en-gb"))}
                                                    </td>
                                                    : <td></td>
                                                }

                                                <td>
                                                    <EditableExpiryDate
                                                        name={event.name ?? ""}
                                                        value={event.expiryDate}
                                                        onUpdate={(value) => handleUpdateExpiryDate(event.eventId, value, event.postId)}
                                                    />
                                                </td>

                                                <td width="180">
                                                    <EventLinksModal
                                                        name={event.name ?? ""}
                                                        value={[event.eventHref ?? "", event.eventSurveyHref ?? ""]}
                                                        onUpdate={(value) => handleUpdateLinks(event.eventId, value, event.postId)}
                                                    />

                                                    <UploadVideoModal
                                                        eventId={event.eventId}
                                                        videoId={event.videos ? event.videos[0]?.videoId : undefined}
                                                        onUpdate={reload}
                                                    />

                                                    <EventDocumentsModal
                                                        event={event}
                                                        onUpdate={reload}
                                                    />

                                                    <UpdateCertificateModal
                                                        mockData={{
                                                            EVENT_NAME: event.name ?? "",
                                                            MEMBER_NAME: "Joseph Bloggs"
                                                        }}
                                                        eventId={event.eventId}
                                                        onUpdate={reload}
                                                    />
                                                </td>
                                                <td>
                                                    <Button
                                                        size="sm"
                                                        variant="outline-primary"
                                                        as={"a"}
                                                        href={event.eventPostHref ?? "#"}
                                                        target="_blank"
                                                        className="ms-2"
                                                        title="View Event Page"
                                                    >
                                                        <i className="bi bi-box-arrow-up-right"></i>
                                                    </Button>                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        }
                    </Col>
                </Row>


                <ButtonGroup className="m-2" >
                    <Button disabled={!previousPage} onClick={previousPage}>
                        Back
                    </Button>
                    <Button disabled={!nextPage} onClick={nextPage}>
                        Next
                    </Button>
                </ButtonGroup>

            </CardBody>
        </Card>
    );
};

export default Events;