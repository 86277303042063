import React from 'react';
import { Badge, Col, FormControl, ListGroup, Row, Spinner } from 'react-bootstrap';
import { MemberNoteProps } from './MemberNoteProps';
import { useMemberNotes } from './useMemberNotes';

const MemberNotes: React.FC<MemberNoteProps> = (props) => {


    const { models, operations } = useMemberNotes({ ...props });
    const { notes, keywords, loading } = models;
    const { setKeywords } = operations;

    return (
        <>

            <Row>
                <Col>
                    {loading ? <Spinner /> : (notes && notes.length > 0) ?
                        <ListGroup className="mt-2" style={{ overflowY: 'auto' }}>
                            {notes && notes.map(note => {
                                return (
                                    <ListGroup.Item key={note.noteId} >
                                        <Row>
                                            <Col xs="auto"><i className="bi bi-sticky me-2"></i></Col>
                                            <Col>
                                                {note.value}
                                            </Col>
                                            <Col xs={"auto"} style={{ textAlign: 'right' }}>

                                                <Badge>
                                                    {new Date(note.createdDate).toLocaleDateString("en-gb")}
                                                </Badge>
                                            </Col>
                                        </Row>

                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                        :
                        <p className="text-muted">There are no notes for this member.</p>
                    }



                </Col>
            </Row>

        </>

    );
};

export default MemberNotes;