import React from 'react';
import { Button, FormSelect, Modal, Spinner } from 'react-bootstrap';
import { CreateMemberEventModalProps } from './CreateMemberEventModalProps';
import { useCreateMemberEvent } from './useCreateMemberEvent';


const CreateMemberEventModal: React.FC<CreateMemberEventModalProps> = ({ ...props }) => {

    const { models, operations } = useCreateMemberEvent({ ...props });
    const { events, eventId, show, inProgress } = models;
    const { handleShow, handleClose, handleSave, setEventId } = operations;


    return (
        <>
            <Button size="sm" variant="outline-primary" className="mb-2 me-2" onClick={handleShow} >
                <i className="bi bi-plus me-1"></i>
                Add Event
            </Button>
            <Modal show={show} onHide={handleClose}>

                <Modal.Header closeButton>
                    <Modal.Title>Add an event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-muted">Select an event for this member to attend</p>
                    {events ?
                        <FormSelect value={eventId} onChange={(e) => setEventId(e.target.value)}>
                            <option></option>
                            {events.map(e => {
                                return (<option key={e.eventId} value={e.eventId}>{e.name}</option>);
                            })}
                        </FormSelect> : <Spinner />
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSave}
                        disabled={inProgress}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateMemberEventModal;