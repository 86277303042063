import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_MEMBERS } from '../../graphql/member/queries';
import { MemberDto, MemberDtoFilterInput, MembersConnection, StringOperationFilterInput } from '../../graphql/__generated__/graphql';



export const useMembers = () => {


    const [memberConnection, setMemberConnection] = useState<MembersConnection>();

    const [members, setMembers] = useState<MemberDto[]>();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [cursor, setCursor] = useState<string>();
    const [keywords, setKeywords] = useState<string>();
    const [expired, setExpired] = useState<string>();

    const [prev, setPrev] = useState(false);

    const keywordsArray = keywords ? keywords.split(' ') : [];
    const capitalizeFirstLetter = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const capitalizedKeywordsArray = keywordsArray.map(word => capitalizeFirstLetter(word));
    const orFilters: {
        firstname?: { contains: string; } | { contains: any; };
        lastname?: { contains: string; } | { contains: any; };
        email?: { contains: string; } | { contains: any; };
    }[] = [];

    keywordsArray.filter(x => x.length > 0).forEach(word => {
        orFilters.push({ firstname: { contains: word } });
        orFilters.push({ lastname: { contains: word } });
        orFilters.push({ email: { contains: word } });
    });

    capitalizedKeywordsArray.filter(x => x.length > 0).forEach(word => {
        orFilters.push({ firstname: { contains: word } });
        orFilters.push({ lastname: { contains: word } });
        orFilters.push({ email: { contains: word } });
    });

    const variables = {
        take: pageSize,
        after: prev === true ? undefined : cursor ?? undefined,
        before: prev === false ? undefined : cursor ?? undefined,
        where: {
            hasActiveMembership: expired !== undefined
                ? { eq: expired === "true" }
                : undefined,
            or: orFilters
        } as MemberDtoFilterInput
    };

    const { data, error, loading } = useQuery(GET_MEMBERS, { variables });



    const nextPage = memberConnection?.pageInfo.hasNextPage ? () => {
        setPrev(false);
        setCursor(memberConnection?.pageInfo.endCursor ?? undefined);
    } : undefined;

    const previousPage = memberConnection?.pageInfo.hasPreviousPage ? () => {
        setPrev(true);
        setCursor(memberConnection?.pageInfo.startCursor ?? undefined);
    } : undefined;



    useEffect(() => {
        if (!loading && !error) {
            let membersResult = data.members as MembersConnection;
            if (memberConnection !== membersResult) {
                setMemberConnection(membersResult);
                setMembers(membersResult?.nodes as MemberDto[]);
            }
        }
    });

    return {
        models: {
            loading, page, keywords, members, expired
        },
        operations: {
            setKeywords, setExpired, nextPage, previousPage
        }
    };
};
