import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_EVENTS_BY_MEMBER_ID } from '../../graphql/event/queries';
import { Event, EventFilterInput, StringOperationFilterInput } from '../../graphql/__generated__/graphql';
import { MemberEventProps } from "./MemberEventProps";



export const useMemberEvents = ({ ...props }) => {

    var { member } = { ...props } as MemberEventProps;

    const [events, setEvents] = useState<Event[]>();
    const [page, setPage] = useState(1);
    const [cursor, setCursor] = useState<string>();
    const [keywords, setKeywords] = useState<string>();
    const [prev, setPrev] = useState(false);

    const variables = {
        id: member.memberId,
        after: prev === true ? undefined : cursor ?? undefined,
        before: prev === false ? undefined : cursor ?? undefined,
        where: {
            name: keywords ? { contains: keywords } as StringOperationFilterInput : undefined
        } as EventFilterInput
    };

    const { data, error, loading, refetch } = useQuery(GET_EVENTS_BY_MEMBER_ID, { variables });

    const handleReloadMemberEvents = () => {
        refetch(variables);
    };

    useEffect(() => {
        if (!loading && !error) {

            setEvents(data.eventsByMemberId.nodes as Event[]);
        }
    }, [data, error, loading]);

    return {
        models: {
            loading, events, keywords, member
        },
        operations: {
            setKeywords, handleReloadMemberEvents
        }
    };
};
