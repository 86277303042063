import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { CREATE_BROADCAST } from '../../graphql/broadcast/mutations';
import { CreateBroadcastCommandInput, MemberDto } from '../../graphql/__generated__/graphql';
import { useEvents } from '../../hooks/useEvents';
import { useSegments } from '../../hooks/useSegments';
import { useMembers } from '../Members/useMembers';
import { CreateBroadcastModalProps } from './CreateBroadcastModalProps';



export const useCreateBroadcast = ({ ...props }) => {

    var { onCreate } = { ...props } as CreateBroadcastModalProps;

    const [show, setShow] = useState(false);
    const [inProgress, setInProgress] = useState(false);

    const [segmentId, setSegmentId] = useState<string>('');
    const [eventId, setEventId] = useState<string>('');
    const [selectedMembers, setSelectedMembers] = useState<MemberDto[]>([]);
    const [subject, setSubject] = useState<string>("");
    const [body, setBody] = useState<string>("");

    const [createBroadcast] = useMutation(CREATE_BROADCAST);

    const { models: eventModels } = useEvents();
    const { events } = eventModels;

    const { models: segmentModels } = useSegments();
    const { segments } = segmentModels;

    const { models: memberModels, operations: memberOperations } = useMembers();
    const { members, keywords } = memberModels;
    const { setKeywords } = memberOperations;

    const handleUpdateSelectedMembers = (memberId: string) => {

        if (!members)
            return;

        const memberExists = selectedMembers.some(x => x.memberId === memberId);

        if (memberExists) {
            setSelectedMembers(selectedMembers.filter(x => x.memberId !== memberId));
        } else {
            var found = members.find(x => x.memberId === memberId);
            if (found)
                setSelectedMembers([...selectedMembers, found]);
        }
        console.log(selectedMembers);
    }

    const handleBroadcast = () => {

        setInProgress(true);

        return new Promise((resolve, reject) => {

            resolve(createBroadcast({
                variables: {
                    broadcast: {
                        body: body,
                        subject: subject,
                        segmentId: segmentId,
                        memberIds: selectedMembers.map(m => m.memberId),
                        eventId: eventId,
                    } as CreateBroadcastCommandInput
                }
            }).then((response) => {
                console.log("create broadcast response", response);
                handleClose();
                setSegmentId('');
                setEventId('');
                setSelectedMembers([]);
                setSubject('');
                setBody('');
                setInProgress(false);
                onCreate("").then(() => {

                })
            }));
        })
    }

    const handleClose = () => { setShow(false); setInProgress(false); };

    const handleShow = () => setShow(true);


    //reset the selections if a new method is chosen
    useEffect(() => {
        if (segmentId.length > 0) {
            setSelectedMembers([]);
            setEventId('');
        }
    }, [segmentId]);

    useEffect(() => {
        if (eventId.length > 0) {
            setSelectedMembers([]);
            setSegmentId('');
        }
    }, [eventId])

    useEffect(() => {
        if (selectedMembers.length === 1) {
            setSegmentId('');
            setEventId('');
        }
    }, [selectedMembers])


    return {
        models: {
            segmentId, eventId, selectedMembers, keywords, members, segments, events, subject, body, inProgress, show
        },
        operations: {
            handleShow, handleClose, setSegmentId, setEventId, handleBroadcast, setKeywords, handleUpdateSelectedMembers, setSubject, setBody
        }
    };
};
