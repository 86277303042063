export let memberGQL = `
 addresses {
                      addressId
                      addressLine1
                      addressLine2
                      town
                      city
                      country
                      postcode
                      default
                    }
            advertisingOptIn
            avatar {
                data
                id
            }
            childTrainingLevel
            childTrainingLevelDateAchieved
            customerId
            dateCreated
            dateUpdated
            declarationAgreed
            email
            emdrTrainingLevel {
                emdrTrainingLevelId
                name
            }
            emdrTrainingLevelDateAchieved
            emdrTrainingLevelId
            firstname
            lastname
            memberId
            membershipNumber
            accreditations {
                accreditationId
                accreditationType {
                    name
                }
                accreditationStartedDate
                paymentRequestedDate
                paymentCompleteDate
                awardedDate
                rejectedDate
                startDate
                endDate
            }
            memberships {
                endDate
                membershipId
                membershipNumber
                membershipTypeId
                startDate
            }
            newsOptIn
            organisation
            languages
            professionalRegistrations {
                name
                professionalRegistrationId
            }
            professions {
                name
                professionId
            }
            qualifications
            region {
                name
                regionId
            }
            regionId
            telephones {
                number
                telephoneId
            }
            title
            userId
            website
`;

export let memberItemGQL = `

            customerId
            firstname
            lastname
            memberId
            membershipNumber        
            organisation
            title
            userId
            website
`;
