import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_SEGMENT_MEMBERS } from '../../../graphql/segment/queries';
import { Member } from '../../../graphql/__generated__/graphql';
import { SegmentPreviewModalProps } from './SegmentPreviewModalProps';



export const useSegmentPreview = ({ ...props }) => {

    var { segmentId } = { ...props } as SegmentPreviewModalProps;

    const { data, error, refetch, loading } = useQuery(GET_SEGMENT_MEMBERS, { variables: { id: segmentId }, fetchPolicy: 'network-only' });

    const [resultsLoading, setResultsLoading] = useState<boolean>(true);
    const [members, setMembers] = useState<Member[]>([]);
    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);

    const handleShow = () => {

        setResultsLoading(true);
        refetch({ id: segmentId }).then((response) => {
            console.log("refetch", response.data);

            let d = response.data

            if (d && d.segmentMembers)
                setMembers(d.segmentMembers as Member[]);
            setResultsLoading(false);
        });
        setShow(true);
    }

    useEffect(() => {
        setResultsLoading(loading);
    }, [loading])

    useEffect(() => {
        if (data && data.segmentMembers)
            setMembers(data.segmentMembers as Member[]);
    }, [data])

    return {
        models: {
            show, members
        },
        operations: {
            handleShow, handleClose, data, resultsLoading, error
        }
    };
};
