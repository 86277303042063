import { useState } from 'react';
import useDocuments from '../../../hooks/useDocuments';
import { UploadDocumentModalProps } from './UploadDocumentModalProps';



export const useUploadDocument = ({ ...props }) => {


    const { uploadFile, error } = useDocuments();

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [expiryDate, setExpiryDate] = useState<string>();
    const [cpd, setCPD] = useState<number>(0);
    const [name, setName] = useState<string>();
    const [filename, setFilename] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [isValid, setIsValid] = useState<boolean>(selectedFile !== null);


    const { memberId, eventId, onUpdate } = { ...props } as UploadDocumentModalProps

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setIsValid(false);
        onUpdate();
        setSelectedFile(null);
        setExpiryDate(undefined);
        setCPD(0)
        setName(undefined);
        setDescription(undefined);
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const handleSave = () => {
        handleUpload().then(() => {
            handleClose();
        })
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const files = event.target.files;
        if (files && files.length > 0) {
            setName(files[0].name);
            setFilename(files[0].name);

            console.log("file", files[0].name);

            setSelectedFile(files[0]);
            setIsValid(true)
        }
    };

    const handleUpload = () => {
        return new Promise(async (resolve) => {
            if (selectedFile) {
                await uploadFile({
                    memberId: memberId,
                    eventId: eventId,
                    cpd: cpd,
                    name: name ?? "",
                    description: description ?? "",
                    expires: expiryDate ? new Date(expiryDate) : null,
                    file: selectedFile,
                    fileName: filename ?? ""
                });

                setSelectedFile(null);
                resolve(true);
            }
        });
    };




    return {
        models: {
            name, description, expiryDate, cpd, show, error, isValid
        },
        operations: {
            handleShow, handleSave, handleClose, setName, setDescription, setExpiryDate, setCPD, handleFileChange
        }
    };
};
