import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_ACCREDITATION_TYPE } from '../../graphql/accreditation/queries';
import { Accreditation, AccreditationType } from '../../graphql/__generated__/graphql';

export const useAccreditation = ({ ...props }) => {

    const { accreditationTypeId } = props;
    const [accreditation, setAccreditation] = useState<AccreditationType>();


    const { data, error, loading, refetch } = useQuery(GET_ACCREDITATION_TYPE, {
        variables: { id: accreditationTypeId }
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAccreditation({
            ...accreditation,
            [e.target.name]: e.target.name === 'price' ? parseFloat(e.target.value) : e.target.value,
        } as AccreditationType);
    };

    const handleReloadAccreditation = () => {
        refetch({ id: accreditationTypeId }).then((resp) => {
            //console.log("response", resp)
        });
    }

    useEffect(() => {
        if (!loading && !error) {
            setAccreditation(data.accreditationType);
        }
    }, [data]);

    return {
        models: {
            accreditation
        },
        operations: {
            handleReloadAccreditation, handleChange
        }
    };
};
