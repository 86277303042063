import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { UploadDocumentModalProps } from './UploadDocumentModalProps';
import { useUploadDocument } from './useUploadDocument';


const UploadDocumentModal: React.FC<UploadDocumentModalProps> = ({ ...props }) => {

    const { models, operations } = useUploadDocument({ ...props });
    const { name, description, expiryDate, cpd, error, show, isValid } = models;
    const { handleShow, handleClose, handleSave, setName, setDescription, setExpiryDate, setCPD, handleFileChange  } = operations;

    return (
        <>
            <Button
                size="sm"
                variant="outline-primary"
                className="mb-2 me-2"
                onClick={handleShow}
            >
                <i className="bi bi-plus me-1"></i>
                Add Document
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {!isValid ? 

                        <Form.Group>
                            <Form.Control
                                type="file"
                                required
                                placeholder="Choose File"
                                onChange={handleFileChange}
                            />
                        </Form.Group>
                        :
                        <>
                            <Form.Group className="mt-2">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group className="mt-2">
                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    placeholder="enter a description..."
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Form.Group>

                            {props.memberId &&
                                <Row className="mt-2">
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>CPD</Form.Label>
                                            <Form.Control
                                                type="number"
                                                step={1}
                                                min={0}
                                                required
                                                value={cpd}
                                                onChange={(e) => setCPD(parseInt(e.target.value))}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Expiry Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                required
                                                value={expiryDate?.toString() ?? ""}
                                                onChange={(e) => setExpiryDate(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                            }


                        </>

                        }




                    {error && <div style={{ color: 'red' }}>{error}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSave}
                        disabled={!isValid}
                    >
                        Upload
                    </Button>
                </Modal.Footer>
            </Modal>
        </>);
};

export default UploadDocumentModal;