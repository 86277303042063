import { gql } from '@apollo/client';
import { eventGQL, eventItemGQL } from '../gql/eventGQL';


export const GET_EVENTS = gql`
  query GetEvents($first:Int $after:String $where:EventFilterInput) {
    events(first:$first after:$after where:$where){
        totalCount
        pageInfo{
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        }
        nodes{
            ${eventGQL}
        }
    }
}
`;

export const GET_EVENTS_BY_MEMBER_ID = gql`
  query GetEventsByMemberId($id:String! $first:Int $after:String $where:EventFilterInput) {
    eventsByMemberId(id:$id first:$first after:$after where:$where){
        totalCount
        pageInfo{
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        }
        nodes{
            ${eventItemGQL}
        }
    }
}
`;

export const GET_EVENT = gql`
  query GetEvent($id:String!){
    event(id:$id){
        ${eventGQL}
    }
  }
`;

export const GET_EVENT_BY_POST_ID = gql`
 query GetEventByPostId($id:String!){
    eventByPostId(id:$id){
        ${eventGQL}
    }
  }
`;

export const GET_WP_EVENT = gql`
  query GetWPEvent($id:string){
    event(id:$id){
        ${eventGQL}
    }
  }
`;

export const GET_EVENT_CERTIFICATE_TEMPLATE = gql`
  query GetEventCertificateTemplate($id:String!){
    eventCertificateTemplate(id:$id)
  }
`;

