import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const AdminLinkButton: React.FC<{
    title: string,
    icon: React.ReactNode,
    href: string,
    onDescribe: (icon: React.ReactNode) => void
}> = ({ title, icon, href, onDescribe }) => {

    const navigate = useNavigate();
    const [hover, setHover] = useState(false);

    const ButtonStyle = (shadow: boolean) => {

        return ({
            width: '10em',
            height: '10em',
            boxShadow: shadow ?
                'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px' :
                'none'
        });
    }

    useEffect(() => {
        if (hover === true)
            onDescribe(<span className="me-2">{icon}</span>);
    }, [hover]);


    return (
        <Button
            variant={"primary"}
            className="m-2"
            style={hover ? ButtonStyle(true) : ButtonStyle(false)}
            onClick={() => { navigate(href) }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <h1>{icon}</h1>
            <h6>{title}</h6>
        </Button>
    );
};

export default AdminLinkButton;