import React from 'react';
import { Button, ButtonGroup, Card, CardBody, Col, FormControl, Row, Spinner, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CreateAccreditationModal from '../Modals/CreateAccreditationModal';
import { useAccreditations } from './useAccreditations';


const Accreditations: React.FC = () => {

    const { models, operations } = useAccreditations();
    const { loading, accreditations, expired, keywords } = models;
    const { setExpired, setKeywords, nextPage, previousPage, handleCreateAccreditation } = operations;

    const navigate = useNavigate();

    return (
        <Card bg="light" border="light" className="w-100">
            <CardBody>

                <Row>
                    <Col style={{ textAlign: 'left' }} >
                        <h3>Accreditations</h3>
                    </Col>
                    <Col xs={"auto"}>
                        <FormControl
                            placeholder={"search..."}
                            value={keywords}
                            onChange={(e) => setKeywords(e.target.value)}
                            className="mb-2"
                        />
                    </Col>
                    <Col xs={"auto"}>
                        <CreateAccreditationModal
                            onCreate={handleCreateAccreditation}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {loading ? <Spinner />
                            : <Table style={{ textAlign: 'left' }}>
                                <thead ><tr><th >Name</th><th /></tr></thead>
                                <tbody>
                                    {accreditations && accreditations.map((accreditation) => {
                                        return (
                                            <tr key={accreditation.accreditationTypeId}>
                                                <td className="text-left">{accreditation.name}</td>
                                                <td>
                                                    <Button
                                                        size="sm"
                                                        variant="outline-primary"
                                                        onClick={() => navigate("/accreditation/" + accreditation.accreditationTypeId)}
                                                    >
                                                        <i className="bi bi-chevron-right"></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        }
                    </Col>
                </Row>


                <ButtonGroup className="m-2" >
                    <Button disabled={!previousPage} onClick={previousPage}>
                        Back
                    </Button>
                    <Button disabled={!nextPage} onClick={nextPage}>
                        Next
                    </Button>
                </ButtonGroup>

            </CardBody>
        </Card>
    );
};

export default Accreditations;