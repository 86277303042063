import React, { useState } from 'react';
import { Button, ButtonGroup, Col, FloatingLabel, Form, Modal, Row, InputGroup } from 'react-bootstrap';
import { IEditableExpiryDateProps } from './IEditableExpiryDateProps';
import { useEditableExpiryDate } from './useEditableExpiryDate';


export const EditableExpiryDate: React.FC<IEditableExpiryDateProps> = ({ name, value, onUpdate }) => {

    const { models, operations } = useEditableExpiryDate({ value, onUpdate });
    const { show, loading, expiry } = models;
    const { setExpiry, handleOpen, handleClose, handleUpdate } = operations

    return (


        <>
            <Button
                variant={value ? "info" : "outline-info"}
                size="sm"
                onClick={() => handleOpen()}
            >
                {value ? new Date(value).toLocaleDateString("en-gb") : "Set Expiry"}
            </Button>

            <Modal show={show} onHide={handleClose} data-testid="member-editablename-modal" >
                {/*<Modal.Header>*/}
                {/*    <span>Updating Expiry Date for <b>{name}</b></span>*/}
                {/*</Modal.Header>*/}

                <Modal.Header closeButton>
                    <Modal.Title>Expiry Date</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Row>
                        <Form.Group>
                            <Form.Label>Expiry Date</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    required
                                    type="date"
                                    name="expiryDate"
                                    value={expiry?.toString() ?? ""}
                                    onChange={(e) => setExpiry(e.target.value)}
                                />
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => setExpiry("")}
                                >
                                    Clear
                                </Button>
                            </InputGroup>
                            <Form.Text>                               
                                <p>The expiry date marks when this event will become unavailable in the member portal. Expired events restrict access to:</p>                                
                                <ul>
                                    <li>Recordings of the event</li>
                                    <li>Documents related to the event</li>
                                    <li>Survey completion / evidence of participation</li>
                                </ul>
                                <p>If no date is specified, the event never expires.</p>
                            </Form.Text>
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <ButtonGroup>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleUpdate}
                            disabled={loading}
                        >
                            Update
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditableExpiryDate;