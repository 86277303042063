import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { CREATE_ACCREDITATION } from '../../graphql/accreditation/mutations';
import { UPDATE_MEMBER } from '../../graphql/member/mutations';
import { GET_MEMBER } from '../../graphql/member/queries';
import { CREATE_MEMBERSHIP } from '../../graphql/membership/mutations';
import { CreateAccreditationCommandInput, CreateMembershipCommandInput, MemberDto, MemberEmdrTrainingLevelInput, UpdateMemberCommandInput } from '../../graphql/__generated__/graphql';

export const useMember = ({ ...props }) => {

    const { memberId } = props;
    const [member, setMember] = useState<MemberDto>();
    const [membershipTypeId, setMembershipTypeId] = useState<string>();
    const [accreditationTypeId, setAccreditationTypeId] = useState<string>();

    const [inProgress, setInProgress] = useState(true);

    const { data, error, loading, refetch } = useQuery(GET_MEMBER, {
        variables: { id: memberId }
    });

    const [updateMember] = useMutation(UPDATE_MEMBER);
    const [createMembership] = useMutation(CREATE_MEMBERSHIP);
    const [createAccreditation] = useMutation(CREATE_ACCREDITATION)

    const handleReloadMember = (id = null) => {

        console.log("ATTEMPTING RELOAD", memberId);

        refetch({ id: id ?? memberId }).then((resp) => {
            setInProgress(false);
        });
    }

    const handleUpdateMember = (updatedMember: MemberDto) => {



        return new Promise((resolve, reject) => {

            var uMember = {
                memberId: updatedMember.memberId,
                emdrTrainingLevel: updatedMember.emdrTrainingLevel?.emdrTrainingLevelId,
                emdrTrainingLevelDateAchieved: updatedMember.emdrTrainingLevelDateAchieved,
                childTrainingLevel: updatedMember.childTrainingLevel,
                childTrainingLevelDateAchieved: updatedMember.childTrainingLevelDateAchieved ?? null

            } as UpdateMemberCommandInput

            console.log("ATTEMPTING TO UPDATE", uMember);


            resolve(updateMember({
                variables: {
                    member: uMember
                }
            }).then(() => {

                console.log("UPDATED");

                handleReloadMember(updatedMember.memberId);
            }).catch((error) => {


                console.log("UH OH", error);

            }));
        });
    };

    const handleCreateMembership = () => {
        setInProgress(true);

        return new Promise((resolve, reject) => {

            if (error)
                reject(error);

            let mtId = membershipTypeId;
            setMembershipTypeId(undefined);


            resolve(createMembership({
                variables: {
                    membership: {
                        memberId: memberId,
                        membershipTypeId: mtId
                    } as CreateMembershipCommandInput
                }
            }).then(() => {
                handleReloadMember();
                console.log(data);
            }));
        });
    };

    const handleCreateAccreditation = () => {
        setInProgress(true);

        return new Promise((resolve, reject) => {

            if (error)
                reject(error);

            let atId = accreditationTypeId;
            setAccreditationTypeId(undefined);

            resolve(createAccreditation({
                variables: {
                    accreditation: {
                        memberId: memberId,
                        accreditationTypeId: atId
                    } as CreateAccreditationCommandInput
                }
            }).then(() => {
                handleReloadMember();
                console.log(data);
            }));
        })
    }



    useEffect(() => {
        if (!loading && !error) {
            setMember(data.member);
            setInProgress(false);
        }
    }, [data]);

    return {
        models: {
            member, membershipTypeId, accreditationTypeId, inProgress
        },
        operations: {
            setMembershipTypeId,
            setAccreditationTypeId,
            handleUpdateMember,
            handleCreateMembership,
            handleCreateAccreditation,
            handleReloadMember
        }
    };
};
