import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row, Form, InputGroup, FormSelect, Stack, Badge } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useAccreditation } from './useAccreditation';


const AccreditationDetail: React.FC = () => {

    const { accreditationTypeId } = useParams();
    const { models, operations } = useAccreditation({ accreditationTypeId });
    const { accreditation } = models;
    const { handleChange } = operations;

    const navigate = useNavigate();

    const [active, setActive] = useState(false);

    return (
        <Card bg="light" border="light" className="w-100">
            <CardBody style={{ textAlign: 'left' }}>

                <Row >
                    <Col xs="auto">
                        <Button variant="outline-primary"
                            onClick={() => navigate("/accreditations")}
                        ><i className="bi bi-chevron-left"></i></Button>
                    </Col>

                    {accreditation && <>
                        <Col>
                            <h3 className="">{accreditation.name}</h3>
                        </Col>
                        <Col xs={"auto"}>
                            <Form.Group as={Stack} direction="horizontal" gap={2}>
                                <FormSelect value={active.toString()} onChange={(e) => { setActive(e.target.value === "true" ? true : false) }}>
                                    <option value="true">Active</option>
                                    <option value="false">Inactive</option>
                                </FormSelect>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    checked={active}
                                    onClick={() => setActive(!active)}
                                />
                            </Form.Group>
                        </Col></>
                    }
                </Row>

                {accreditation && (
                    <Row className="mt-1">
                        <Form onSubmit={() => { console.log("submit") }}>

                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formAccreditationName">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            value={accreditation.name?.toString()}
                                            onChange={handleChange}
                                            placeholder="Enter accreditation name"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs="auto">
                                    <Form.Group className="mb-3" controlId="formAccreditationPrice">
                                        <Form.Label>Price</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text>&pound;</InputGroup.Text>
                                            <Form.Control
                                                type="number"
                                                name="price"
                                                value={accreditation.price}
                                                onChange={handleChange}
                                                placeholder="0.00"
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formAccreditationDescription">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="description"
                                            as={"textarea"}
                                            value={accreditation.description?.toString()}
                                            onChange={handleChange}
                                            placeholder="Enter accreditation description"
                                        />
                                    </Form.Group>
                                </Col>

                            </Row>

                            {/*<Form.Group className="mb-3" controlId="formApplicationUrl">*/}
                            {/*    <Form.Label>Application URL</Form.Label>*/}
                            {/*    <Form.Control*/}
                            {/*        type="text"*/}
                            {/*        name="applicationUrl"*/}
                            {/*        value={accreditation.applicationUrl?.toString()}*/}
                            {/*        onChange={handleChange}*/}
                            {/*        placeholder="Enter application URL"*/}
                            {/*    />*/}
                            {/*</Form.Group>*/}

                            {/*<Form.Group className="mb-3" controlId="formWebhookUrl">*/}
                            {/*    <Form.Label>Webhook URL</Form.Label>*/}
                            {/*    <Form.Control*/}
                            {/*        type="text"*/}
                            {/*        name="webhookUrl"*/}
                            {/*        value={accreditation.webhookUrl?.toString()}*/}
                            {/*        onChange={handleChange}*/}
                            {/*        placeholder="Enter webhook URL"*/}
                            {/*    />*/}
                            {/*</Form.Group>*/}

                            <Button variant="primary" type="submit">
                                Save
                            </Button>
                        </Form>
                    </Row>
                )}


            </CardBody>
        </Card >
    );
};

export default AccreditationDetail;