import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { MemberDto, Membership } from '../../graphql/__generated__/graphql';
import MemberSectionContainer from '../MemberSectionContainer';
import UpdateMemberDetailsModal from '../Modals/UpdateMemberDetailsModal';
import { MemberDetailLabel } from './MemberDetailLabel';
import { useMember } from './useMember';


const MemberDetailsSection: React.FC<{ member: MemberDto }> = ({ member }) => {

    const { models, operations } = useMember(member.memberId);

    const { handleUpdateMember } = operations

    return (
        <MemberSectionContainer
            title="Details"
            action={<UpdateMemberDetailsModal
                member={member}
                onUpdate={handleUpdateMember}
            />}
        >
            <Card>
                <Card.Body>
                    <div>
                        <MemberDetailLabel title="EMDR Training Level" />
                        <p>{member.emdrTrainingLevel?.name ?? "-"}
                            <small className="ms-2">(Achieved: {new Date(member.emdrTrainingLevelDateAchieved).toLocaleDateString("en-GB")})</small>
                        </p>
                    </div>
                    <div>
                        <MemberDetailLabel title="Child Training Level" />
                        <p>{member.childTrainingLevel ?? "-"}
                            {member.childTrainingLevelDateAchieved &&
                                <small className="ms-2">
                                    (Achieved: {new Date(member.childTrainingLevelDateAchieved).toLocaleDateString("en-GB")})
                                </small>
                            }
                        </p>
                    </div>
                    <div>
                        <MemberDetailLabel title="Languages" />
                        <p>{member.languages ?? "-"}</p>
                    </div>
                    <div>
                        <MemberDetailLabel title="Region" />
                        <p>{member.region?.name ?? "-"}</p>
                    </div>
                    <div>
                        <MemberDetailLabel title="Profession" />
                        {member.professions?.map(profession => {
                            return <p key={profession.professionId}>{profession.name}</p>
                        })}
                    </div>
                    <div>
                        <MemberDetailLabel title="Professional Registration" />
                        {member.professionalRegistrations?.map(pr => {
                            return <p key={pr.professionalRegistrationId}>{pr.name}</p>
                        })}
                    </div>
                    <div>
                        <MemberDetailLabel title="Qualifications" />
                        <p>{member.qualifications ?? "-"}</p>
                    </div>
                </Card.Body>
            </Card>
        </MemberSectionContainer>
    );
};

export default MemberDetailsSection;