import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_MEMBERSHIP } from '../../graphql/membership';
import { UPDATE_MEMBERSHIP, TEST_PAYMENT } from '../../graphql/membership/mutations';
import { Membership, UpdateMembershipCommandInput, PaymentTakenNotificationInput } from '../../graphql/__generated__/graphql';



export const useMembership = ({ ...props }) => {

    const { membershipId } = props;

    const { data, error, loading, refetch } = useQuery(GET_MEMBERSHIP, {
        variables: { id: membershipId }
    });

    const [updateMembership] = useMutation(UPDATE_MEMBERSHIP);
    const [testPayment] = useMutation(TEST_PAYMENT);


    const [membership, setMembership] = useState<Membership>();

    const handleUpdateMembership = (updatedMembership: Membership) => {
        return new Promise((resolve, reject) => {

            if (error)
                reject(error);

            resolve(updateMembership({
                variables: {
                    membership: {
                        membershipId: updatedMembership.membershipId,
                        endDate: updatedMembership.endDate
                    } as UpdateMembershipCommandInput
                }
            }).then(() => {
                refetch({ id: updatedMembership.membershipId });
                console.log(data);
            }));
        });
    };


    const handlePayment = () => {
        return new Promise((resolve, reject) => {

            if (error)
                reject(error);

            resolve(testPayment({
                variables: {
                    notification: {
                        memberId: membership?.memberId,
                        amount: 10.00,
                        description: membership?.membershipType.name
                    } as PaymentTakenNotificationInput
                }
            }).then(() => {
                refetch({ id: membership?.membershipId });
                console.log("Test payment sent for" + membershipId);
            }));
        });
    }

    const isExpired = (): boolean => {
        if (membership && membership.endDate) {
            const currentDate = new Date();
            const endDate = new Date(membership.endDate);
            return currentDate > endDate;
        }

        return false;
    };

    useEffect(() => {
        if (!loading && !error) {
            setMembership(data.membership);
        }
    });

    return {
        models: {
            membership
        },
        operations: {
            handleUpdateMembership, handlePayment, isExpired
        }
    };
};
