import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { useQuery } from '@apollo/client';
import { GET_BROADCAST_STATISTICS } from '../../graphql/broadcast/queries';
import { BroadcastStatistic, Metrics } from '../../graphql/__generated__/graphql';
import { Metric } from 'web-vitals';
import { Card, Spinner, Button } from 'react-bootstrap';

//interface MetricsData {
//    date: string;
//    stats: {
//        metrics: {
//            [key: string]: number;
//        };
//    }[];
//}



const BroadcastsLineChart: React.FC = () => {

    const [chartType, setChartType] = useState<"bar" | "line">("bar");
    const { data, loading, error } = useQuery(GET_BROADCAST_STATISTICS);

    const chartRef = useRef<HTMLCanvasElement>(null);

    const dayOfWeek = (dateString: string): string => {
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const date = new Date(dateString);
        const dayOfWeekIndex = date.getUTCDay();

        return daysOfWeek[dayOfWeekIndex];
    }


    useEffect(() => {

        if (loading || !data.broadcastStatistics)
            return;

        // Extracting necessary information for Chart.js
        const dates: string[] = data.broadcastStatistics.map((item: BroadcastStatistic) => dayOfWeek(item.date));
        const metricsArray: Metrics[] = data.broadcastStatistics.map((item: BroadcastStatistic) => item.stats[0].metrics);

        // Prepare data for Chart.js
        const labels = Object.keys(metricsArray[0]).filter(x => x !== "__typename");
        const datasets = labels.map((label, i) => ({
            label: label,
            data: metricsArray.map((metrics: any) => metrics[label]),
            borderWidth: 6,
            fill: false,
        }));

        // Create a multi-line chart
        if (chartRef.current) {
            const ctx = chartRef.current.getContext('2d');
            if (ctx) {
                const myChart = new Chart(ctx, {
                    type: chartType,
                    data: {
                        datasets: datasets,
                    },
                    options: {
                        scales: {
                            x: {
                                type: 'category',
                                labels: dates,
                                position: 'bottom'
                            },
                            y: {
                                beginAtZero: true,
                            },
                        },

                        plugins: {
                            legend: {
                                display: true,
                                labels: { boxHeight: 5, boxWidth: 5 },
                                position: 'bottom'
                            },
                            title: {
                                display: true,
                                text: 'Week\'s Statistics',
                            },
                            tooltip: {
                                padding: 15,
                                boxPadding: 10, boxHeight: 2, boxWidth: 2
                            }
                        },
                    },
                });
                return () => { myChart.destroy() }
            }
        }



    }, [loading, chartType]);

    return <Card className="text-center mb-2">
        <Card.Body className="w-100">
            {loading ? <Spinner /> : <canvas ref={chartRef} style={{ maxHeight:'250px' }} />}
            <Button
                size="sm"
                variant="outline-primary"
                title="toggle graph view"
                active={chartType === "line"}
                className="position-absolute top-0 end-0 m-2"
                onClick={() => setChartType(chartType === "bar" ? "line" : "bar")}
            >
                <i className="bi bi-graph-up"></i>
            </Button>
        </Card.Body>
    </Card>;
};

export default BroadcastsLineChart;