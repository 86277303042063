import { gql } from '@apollo/client';
import { memberGQL } from '../gql/memberGQL';


export const UPDATE_MEMBER = gql`
  mutation memberUpdateMutation($member:UpdateMemberCommandInput) {
    memberUpdate(member: $member) {       
       ${memberGQL}
    }
  }
`;

export const UPDATE_MEMBER_DOCUMENT_APPROVAL = gql`
  mutation memberDocumentApprovalUpdateMutation($memberDocument:UpdateMemberDocumentCommandInput) {
    memberUpdateDocumentApproval(memberDocument: $memberDocument) {
       ${memberGQL}
    }
  }
`;