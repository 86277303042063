import { useEffect, useState } from 'react';

export const useEditableExpiryDate = ({ value, onUpdate }: { value: string, onUpdate: (value?: string) => Promise<any> }) => {

    const [show, setShow] = useState(false);
    const [expiry, setExpiry] = useState<string>();
    const [loading, setLoading] = useState(false);

    const handleUpdate = () => {
        setLoading(true);
        onUpdate(expiry).then(() => {
            handleClose();
            setLoading(false);
        });
    }

    const handleOpen = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    useEffect(() => {
        if (value && !expiry) {
            setExpiry(new Date(value).toISOString().slice(0, 10));
        }
    }, [value, expiry])



    return {
        models: { show, expiry, loading },
        operations: { setExpiry, handleOpen, handleClose, handleUpdate }
    };

};
