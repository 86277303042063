import { gql } from '@apollo/client';

let broadcastGQL = `
    broadcastId
    createdDate
    sentDate
    recipients {
        emailAddress
        failed
        sent
    }
`

export const CREATE_BROADCAST = gql`
  mutation broadcastCreateMutation($broadcast:CreateBroadcastCommandInput) {
    broadcastCreate(broadcast: $broadcast) {
       ${broadcastGQL}
    }
  }
`;
