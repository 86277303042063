
export const eventGQL = `
    cancelled
    createdDate
    eventId
    postId
    cpd
    childAndAdolescent
    eventHref
    eventPostHref
    eventSurveyHref
    name
    participants {
        cancelled
        createdDate
        eventId
        eventParticipantId
        memberId
        emailAddress
    }
    videos {
        videoId
    }
    documents {
        documentId
        name
        description
    }

    memberPrice
    nonMemberPrice
    startDate
    endDate
    expiryDate
`;

export const eventItemGQL = `
    cancelled
    createdDate
    startDate
    eventId
    name
    participants {
        cancelled
        eventParticipantId
    }
`;

export const eventParticipantGQL = `
    cancelled
    createdDate
    emailAddress
    eventId
    eventParticipantId
    memberId
`;