import React from 'react';
import AceEditor from 'react-ace';
import { Alert, Badge, Button, Modal, Stack, Tab, Tabs } from 'react-bootstrap';
import { UpdateCertificateModalProps } from './UpdateCertificateModalProps';
import { useUpdateCertificate } from './useUpdateCertificate';

import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-xcode';

const UpdateCertificateModal: React.FC<UpdateCertificateModalProps> = (props) => {

    const { models, operations } = useUpdateCertificate({ ...props });
    const { show, key, htmlContent, inProgress } = models;
    const { handleShow, handleClose, handleSave, setKey, handleHtmlChange, createMarkup } = operations

    return (
        <>
            <Button
                size="sm"
                variant={htmlContent.length > 0 ? "secondary" : "outline-secondary"}
                onClick={handleShow}
                className="ms-2"
                title="Event Certificate"
            >
                <i className="bi bi-award"></i>
            </Button>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Certificate Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        id="certificate-tabs"
                        activeKey={key}
                        onSelect={(k) => setKey(k || 'edit')}
                        className="mb-3"
                    >
                        <Tab eventKey="edit" title="Design">
                            <Alert variant="light">
                                <p>
                                    Certificate templates must use valid html. Please check the preview to ensure the certificate appears correctly before issuing it.
                                    You can add unique information to the certificate using the codes available below.
                                </p>
                                <Stack className="mb-2" direction="horizontal" gap={2}>
                                    {["[MEMBER_NAME]", "[MEMBERSHIP_NUMBER]", "[EVENT_NAME]", "[ISSUE_DATE]"].map(code => {
                                        return (
                                            <Badge
                                                key={code}
                                                pill
                                                onClick={() => navigator.clipboard.writeText(code)}
                                                title="click to copy"
                                                style={{ cursor: 'pointer' }}>
                                                {code}
                                            </Badge>
                                        );
                                    })}
                                </Stack>
                            </Alert>

                            <div style={{ borderRadius: '5px', overflow: 'hidden' }}>
                                <AceEditor
                                    mode="html"
                                    theme="xcode"
                                    name="htmlEditor"
                                    onChange={(value) => handleHtmlChange(value)}
                                    fontSize={14}
                                    showPrintMargin={true}
                                    showGutter={true}
                                    highlightActiveLine={true}
                                    value={htmlContent}
                                    setOptions={{
                                        useWorker: false,
                                        enableBasicAutocompletion: false,
                                        enableLiveAutocompletion: false,
                                        enableSnippets: false,
                                        showLineNumbers: true,
                                        showPrintMargin: false,
                                        tabSize: 2,
                                    }}
                                    style={{ width: '100%', height: '300px' }}
                                />
                            </div>

                        </Tab>
                        <Tab eventKey="preview" title="Preview">
                            <div dangerouslySetInnerHTML={createMarkup()} />
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button
                        variant="primary"
                        onClick={handleSave}
                        disabled={inProgress}
                    >
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

export default UpdateCertificateModal;
