import { gql } from '@apollo/client';
import { memberGQL } from '../gql/memberGQL';

export const GET_MEMBER = gql`
  query GetMember($id:UUID!){
    member(id:$id){
        ${memberGQL}
        documents {
            documentId
            memberId
            name
            description
            expiries
            cpd
            cpdRequested
            cpdRejected
            cpdReceived
        }
        notes {
            noteId
            memberId
            value
            createdDate
        }
    }
  }
`;

export const GET_MEMBERS = gql`
  query GetMembers($first:Int $after:String $where:MemberDTOFilterInput) {
    members(first:$first after:$after where:$where){
        totalCount
        pageInfo{
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        }
        nodes{
           ${memberGQL}
        }
    }
}
`;


export const GET_EMDR_TRAINING_LEVELS = gql`
  {
    emdrTrainingLevels {
        emdrTrainingLevelId,
        name
    }
  }
`;
