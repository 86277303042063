import React from 'react';
import { Alert, Button, Form, Modal, ProgressBar } from 'react-bootstrap';
import { UploadVideoModalProps } from './UploadVideoModalProps';
import { useUploadVideo } from './useUploadVideo';


const UploadVideoModal: React.FC<UploadVideoModalProps> = ({ ...props }) => {

    const { models, operations } = useUploadVideo({ ...props });
    const { name, description, error, show, isValid, videoSrc, inProgress, progress, uploadedSizeMb, totalSizeMb } = models;
    const { handleShow, handleClose, handleSave, setName, setDescription, handleFileChange } = operations;

    const MemberId = "00000000-0000-0000-0000-000000000000";

    return (
        <>
            <Button
                size="sm"
                variant={videoSrc ? "secondary" : "outline-secondary"}
                onClick={handleShow}
                className="ms-2"
                title="Event Recording"
            >
                <i className="bi bi-camera-video"></i>
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Video Recording</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {videoSrc && <div className="current-video-preview">
                        <Alert>
                            <div className="video-container">
                                <video
                                    controls
                                    controlsList="nodownload"
                                    onContextMenu={(e) => { e.preventDefault() }}
                                    width="100%">
                                    <source src={videoSrc} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className="watermark">Member ID {MemberId}. Do not distribute.</div>
                            </div>
                        </Alert>
                        <Alert variant="light">
                            Uploading a new video will replace the one currently set for this event.
                        </Alert>
                    </div>
                    }

                    {!isValid ?

                        <Form.Group>
                            <Form.Control
                                type="file"
                                accept="video/mp4"
                                required
                                placeholder="Choose File"
                                onChange={handleFileChange}
                            />
                        </Form.Group>
                        :
                        <>
                            <Form.Group className="mt-2">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    disabled={inProgress}
                                />
                            </Form.Group>

                            <Form.Group className="mt-2">
                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    placeholder="enter a description..."
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    disabled={inProgress}
                                />
                            </Form.Group>


                        </>

                    }

                    {error && <div style={{ color: 'red' }}>{error}</div>}
                </Modal.Body>
                <Modal.Footer>
                    {progress > 0 &&
                        <ProgressBar
                            animated
                            now={(progress * 100)}
                            label={uploadedSizeMb.toFixed(2) + " / " + totalSizeMb.toFixed(2) + " MB"}
                            className="my-1 w-50"
                            variant="primary"
                        />
                    }

                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSave}
                        disabled={!isValid || inProgress}
                    >
                        Upload

                    </Button>
                </Modal.Footer>
            </Modal>
        </>);
};

export default UploadVideoModal;