import React from 'react';
import { Card, FormControl, Spinner, Stack, Table } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';
import { Tabs, Tab } from 'react-bootstrap';
import { Alert, Button, FormSelect, Modal } from 'react-bootstrap';
import { CreateBroadcastModalProps } from './CreateBroadcastModalProps';
import { useCreateBroadcast } from './useCreateBroadcast';


const CreateBroadcastModal: React.FC<CreateBroadcastModalProps> = ({ ...props }) => {

    const { models, operations } = useCreateBroadcast({ ...props });
    const { segmentId, eventId, selectedMembers, segments, members, events, keywords, inProgress, show, subject, body } = models;
    const { handleShow, handleClose, handleBroadcast, setSegmentId, setEventId, handleUpdateSelectedMembers, setKeywords, setSubject, setBody } = operations;


    const hasRecipient = () => {
        if (segmentId.length > 0) return true;
        if (selectedMembers.length > 0) return true;
        if (eventId.length > 0) return true;
        return false;
    }

    const renderRecipients = () => {

        let recipients: { key: string, name: string }[] = [];
        let icon = "";

        if (segmentId.length > 0) {
            icon = "bi bi-person-rolodex";
            let selected = segments?.filter(x => x.segmentId === segmentId)[0];
            if (selected)
                recipients.push({ key: selected.segmentId, name: selected.name });
        }

        if (eventId.length > 0) {
            icon = "bi bi-calendar-event";
            let selected = events?.filter(x => x.eventId === eventId)[0];
            if (selected)
                recipients.push({ key: selected.eventId, name: selected.name ?? "" });
        }

        if (selectedMembers.length > 0) {
            icon = "bi bi-person";
            selectedMembers.map(member => recipients.push({ key: member.memberId, name: (member.firstname + " " + member.lastname) }))
        }

        return (<>
            {recipients.map((recipient) => {
                return <Badge key={recipient.key} pill><i className={icon + " me-1"}></i>{recipient.name}</Badge>
            })}
        </>);
    }

    return (
        <>
            <Button variant="outline-primary" onClick={handleShow} >
                <i className="bi bi-plus me-1"></i>
                New Broadcast
            </Button>
            <Modal show={show} onHide={handleClose} size={inProgress ? "sm" : "lg"}>
                {inProgress ?
                    <Modal.Body>
                        <div className="w-100 text-center">
                            <Spinner />
                            <p>sending broadcast...</p>
                        </div>
                    </Modal.Body> : <>
                        <Modal.Header closeButton>
                            <Modal.Title>Create a new broadcast</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Tabs defaultActiveKey="segment" >
                                <Tab disabled title={"Broadcast to:"}></Tab>
                                <Tab title={"Segment"} eventKey="segment" className="p-3 border">
                                    {segments ?
                                        <FormSelect value={segmentId} onChange={(e) => setSegmentId(e.target.value)}>
                                            <option></option>
                                            {segments.map(s => {
                                                return (<option value={s.segmentId}>{s.name}</option>);
                                            })}
                                        </FormSelect> : <Spinner />
                                    }
                                </Tab>
                                <Tab title={"Members"} eventKey="members" className="p-3 border">
                                    <FormControl
                                        placeholder={"search..."}
                                        value={keywords}
                                        onChange={(e) => setKeywords(e.target.value)}
                                        className="mb-2"
                                    />
                                    <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                        <Table>
                                            <thead><tr><th>Member</th><th>Email</th><th /></tr></thead>
                                            <tbody>
                                                {members && members.slice(0, 5).map((member) => {
                                                    return (
                                                        <tr key={member.memberId}>
                                                            <td><i className="bi bi-person-fill"></i> {member.firstname} {member.lastname}</td>
                                                            <td>{member.email}</td>
                                                            <td>
                                                                {selectedMembers.includes(member) ?
                                                                    <Button
                                                                        size="sm"
                                                                        variant="outline-danger"
                                                                        onClick={() => { handleUpdateSelectedMembers(member.memberId) }}
                                                                    >
                                                                        <i className="bi bi-dash-lg"></i>
                                                                    </Button>
                                                                    :
                                                                    <Button
                                                                        size="sm"
                                                                        variant="outline-primary"
                                                                        onClick={() => { handleUpdateSelectedMembers(member.memberId) }}
                                                                    >
                                                                        <i className="bi bi-plus-lg"></i>
                                                                    </Button>
                                                                }


                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Tab>
                                <Tab title={"Event"} eventKey="event" className="p-3 border">
                                    {events ?
                                        <FormSelect value={eventId} onChange={(e) => setEventId(e.target.value)}>
                                            <option></option>
                                            {events.map(e => {
                                                return (<option value={e.eventId}>{e.name}</option>);
                                            })}
                                        </FormSelect> : <Spinner />
                                    }
                                </Tab>
                            </Tabs>
                            <Card className="mt-4 p-2">
                                <Alert variant="light" className="p-2" style={{ overflow: 'auto' }}>
                                    <Stack direction="horizontal" gap={1}>
                                        <span className="text-muted mx-1">To:</span>
                                        {renderRecipients()}
                                    </Stack>
                                </Alert>
                                <FormControl
                                    placeholder={"Subject..."}
                                    className="mb-2"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                />
                                <FormControl
                                    placeholder={"Body..."}
                                    className="mb-2"
                                    as={"textarea"}
                                    rows={4}
                                    value={body}
                                    onChange={(e) => setBody(e.target.value)}
                                />
                            </Card>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                variant="primary"
                                onClick={handleBroadcast}
                                disabled={subject.length === 0 || body.length === 0 || !hasRecipient()}
                            >
                                Send
                            </Button>
                        </Modal.Footer>
                    </>}


            </Modal>
        </>
    );
};

export default CreateBroadcastModal;