import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { CREATE_EVENT, UPDATE_EVENT_EXPIRY_DATE, UPDATE_EVENT_LINKS } from '../graphql/event/mutations';
import { GET_EVENT, GET_EVENTS } from '../graphql/event/queries';
import { CreateEventCommandInput, Event, EventFilterInput, EventsConnection, StringOperationFilterInput, UpdateEventExpiryDateCommandInput, UpdateEventLinksCommandInput } from '../graphql/__generated__/graphql';



export const useEvents = () => {

    const [eventConnection, setEventConnection] = useState<EventsConnection>();

    const [events, setEvents] = useState<Event[]>();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [cursor, setCursor] = useState<string>();
    const [keywords, setKeywords] = useState<string>();

    const [prev, setPrev] = useState(false);

    const variables = {
        take: pageSize,
        after: prev === true ? undefined : cursor ?? undefined,
        before: prev === false ? undefined : cursor ?? undefined,
        where: {
            or: [
                { name: keywords ? { contains: keywords } as StringOperationFilterInput : undefined },
                { startDate: keywords ? { contains: keywords } as StringOperationFilterInput : undefined }
            ]
        } as EventFilterInput
    };

    const { data, error, loading, refetch } = useQuery(GET_EVENTS, { fetchPolicy: 'network-only', variables });

    const [updateEventExpiryDate] = useMutation(UPDATE_EVENT_EXPIRY_DATE);
    const [updateEventLinks] = useMutation(UPDATE_EVENT_LINKS);
    const [createEvent] = useMutation(CREATE_EVENT);



    const nextPage = eventConnection?.pageInfo.hasNextPage ? () => {
        setPrev(false);
        setCursor(eventConnection?.pageInfo.endCursor ?? undefined);
    } : undefined;

    const previousPage = eventConnection?.pageInfo.hasPreviousPage ? () => {
        setPrev(true);
        setCursor(eventConnection?.pageInfo.startCursor ?? undefined);
    } : undefined;

    const handleCreate = async (postId?: string): Promise<string> => {

        return new Promise((resolve, reject) => {

            if (postId) {
                let thisEvent = events?.filter(x => x.postId === postId)[0];
                if (thisEvent)
                    createEvent({
                        variables: {
                            evt: {
                                name: thisEvent.name,
                                postId: thisEvent.postId,

                                //TODO: dates/prices/etc...

                            } as CreateEventCommandInput
                        }
                    }).then((response) => {
                        resolve(response.data.eventCreate.eventId);
                    })
            } else reject();
        })
    }

    const handleUpdateExpiryDate = (eventId: string, expiryDate?: string, postId?: string) => {

        return new Promise(async (resolve, reject) => {

            //Does the event exist?
            if (eventId === "00000000-0000-0000-0000-000000000000") {
                eventId = await handleCreate(postId);
            }

            resolve(updateEventExpiryDate({
                variables: {
                    evt: {
                        eventId,
                        expiryDate: expiryDate ? new Date(expiryDate) : undefined
                    } as UpdateEventExpiryDateCommandInput
                }
            }).then(async () => {
                await reload();
            }));
        })
    }

    const handleUpdateLinks = (eventId: string, value?: string[], postId?: string) => {

        return new Promise(async (resolve, reject) => {

            //Does the event exist?
            if (eventId === "00000000-0000-0000-0000-000000000000") {
                eventId = await handleCreate(postId);
            }

            resolve(updateEventLinks({
                variables: {
                    evt: {
                        eventId,
                        href: value ? value[0] : value,
                        surveyHref: value ? value[1] : value
                    } as UpdateEventLinksCommandInput
                }
            }).then(async () => {
                await reload();
            }));
        })
    }

    const reload = async () => {
        await refetch(variables);

    }

    useEffect(() => {
        if (!loading && !error) {
            let eventsResult = data.events as EventsConnection;
            if (eventConnection !== eventsResult) {


                console.log("evt result", eventsResult);

                setEventConnection(eventsResult);
                setEvents(eventsResult?.nodes as Event[]);
            }
        }
    });

    return {
        models: {
            loading, page, keywords, events
        },
        operations: {
            reload, setKeywords, nextPage, previousPage, handleUpdateExpiryDate, handleUpdateLinks
        }
    };
};
