import React from 'react';
import { Button, Card, CardBody } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import imgSrc from '../../logo.png';

const Home: React.FC = () => {

    const navigate = useNavigate();

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Card bg="light" border="light">
                <CardBody>
                    <div className="d-flex justify-content-center text-center">
                        <div className="d-flex flex-column">
                            <img src={imgSrc} className="mb-4" ></img>
                            <h6 >C O A L E S C E</h6>
                            <h6 className="mb-4">Administration Portal</h6>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center text-center">
                        <div className="d-flex flex-column">
                            <Button className="mb-2" size="lg" onClick={() => navigate("/dashboard")} >Sign In</Button>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export default Home;